import { FC, useRef, useState } from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import OTPInput from "react-otp-input";

import cs from "classnames";

import { AccountNotFoundError, otpLogin } from "../../api/idp";
import {
  generateEmailConfirmationToken,
  generateOTPCode,
} from "../../api/ncwallet";
import {
  PARENT_MESSAGE_TYPE,
  sendMessageToParent,
} from "../../api/parentWindow";
import { redirectOnSuccess } from "../../helpers/redirectOnSuccess";
import { useMounted } from "../../hooks/useMounted";
import commonStyles from "../../styles/common.module.scss";
import { usePublicSettings } from "../PublicSettingsProvider";
import styles from "./styles.module.scss";

const isValidOtpCode = (otpCode: string) => otpCode.length === 6;

type OTPFormScreenProps = {
  onBack: () => void;
  onError: (msg: string) => void;
  email: string;
  returnUrl: string;
  onAccountNotFound: (email: string) => void;
};

const OTPFormScreen: FC<OTPFormScreenProps> = ({
  email,
  onBack,
  onError,
  returnUrl,
  onAccountNotFound,
}) => {
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState<string>("");
  const [otpCode, setOtpCode] = useState("");
  const [publicSettings] = usePublicSettings();
  const ref = useRef<HTMLInputElement | null>(null);

  useMounted(async () => {
    try {
      ref.current?.focus();
      setTimeout(() => {
        ref.current?.focus();
      }, 1000);
      await generateOTPCode({ email });
      setLoading(false);
    } catch (err) {
      console.error(err);
      onError("Otp code wasn't sent, try again later or contact administrator");
      onBack();
    }
  });

  const requestAccountCreation = async (props: {
    email: string;
    otpCode: string;
  }) => {
    try {
      const params = await generateEmailConfirmationToken({
        otp: props,
      });
      sendMessageToParent({
        type: PARENT_MESSAGE_TYPE.ACCOUNT_CREATION_REQUEST,
        message: params,
      });
    } catch (err: any) {
      console.log(err?.message);
      onError("Account creation failed");
    }
  };

  const handleOtpCodeChange = async (code: string) => {
    setOtpCode(code);
    if (!isValidOtpCode(code)) {
      return;
    }

    setLoading(true);
    try {
      const response = await otpLogin({
        email,
        returnUrl: returnUrl.toString(),
        otpCode: code,
      });
      await redirectOnSuccess(response.returnUrl);
    } catch (err: any) {
      console.error(err?.message);
      if (err instanceof AccountNotFoundError) {
        if (publicSettings?.allowCreation) {
          requestAccountCreation({ email, otpCode });
        } else {
          onAccountNotFound(email);
        }
      } else {
        setOtpCode("");
        setFormError("Incorrect OTP code");
        setLoading(false);
      }
    }
  };

  return (
    <div className={commonStyles.stack}>
      <p className={commonStyles.h1}>
        <IoChevronBackCircleOutline
          onClick={onBack}
          className={commonStyles.inlineBackBtn}
        />
        Confirm email
        <br />
        <span
          className={cs(
            commonStyles.text,
            commonStyles.textGrey,
            styles.subtitle
          )}
        >
          OTP code has been sent to
          <br />
          {email}
        </span>
      </p>
      <OTPInput
        value={otpCode}
        onChange={handleOtpCodeChange}
        numInputs={6}
        containerStyle={{ gap: "10px", justifyContent: "center" }}
        renderInput={(props, index) => (
          <input
            {...props}
            className={cs(props.className, styles.otpInput)}
            name="code"
            disabled={loading}
            ref={(input) => {
              index === 0 && (ref.current = input);
              props.ref(input);
            }}
          />
        )}
      />
      {formError && <p className={commonStyles.textError}>{formError}</p>}
    </div>
  );
};
export default OTPFormScreen;
