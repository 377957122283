import { useCallback } from "react";

import {
  AccountNotFoundError,
  googleLogin,
  type GoogleLoginBody,
} from "../api/idp";
import { usePublicSettings } from "../components/PublicSettingsProvider";

type UseGoogleLoginProps = {
  onSuccess: (returnUrl: string) => void;
  onAccountNotFound: () => void;
  onAccountCreationRequest: (params: { idToken: string }) => void;
  onError: (msg: string) => void;
};

export const useGoogleLogin = ({
  onSuccess,
  onAccountNotFound,
  onAccountCreationRequest,
  onError,
}: UseGoogleLoginProps) => {
  const [publicSettings] = usePublicSettings();
  const loginHandler = useCallback(async (data: GoogleLoginBody) => {
    try {
      const response = await googleLogin(data);
      onSuccess(response.returnUrl);
    } catch (error: any) {
      console.error(error);
      if (error instanceof AccountNotFoundError) {
        if (publicSettings?.allowCreation) {
          onAccountCreationRequest({ idToken: data.token });
        } else {
          onAccountNotFound();
        }
      } else {
        onError(error?.msg || "Failed");
      }
    }
  }, []);

  return loginHandler;
};
