import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import * as Sentry from "@sentry/react";

import "./App.css";
import "./lib/sentry/init";
import LoginPage from "./pages/LoginPage";

// const LoginPage = lazy(() => import("./pages/LoginPage"));
const LoginRequestPage = lazy(() => import("./pages/LoginRequestPage"));
const LoginResponsePage = lazy(() => import("./pages/LoginResponsePage"));
const CreationPage = lazy(() => import("./pages/CreationPage"));
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <SentryRoutes>
      <Route index element={<LoginPage />} />
      <Route
        path="/login-request"
        element={
          <Suspense fallback="">
            <LoginRequestPage />
          </Suspense>
        }
      />
      <Route
        path="/login-response"
        element={
          <Suspense fallback="">
            <LoginResponsePage />
          </Suspense>
        }
      />
      <Route
        path="/create-by-token"
        element={
          <Suspense fallback="">
            <CreationPage />
          </Suspense>
        }
      />
    </SentryRoutes>
  );
}

export default App;
