export enum EVENT_TYPE_FROM_REQUEST_PAGE {
  READY = "READY",
  LOGIN_ERROR = "LOGIN_ERROR",
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
  ACCOUNT_CREATION_REQUEST = "ACCOUNT_CREATION_REQUEST",
}

export type RequestPageReadyData = {
  type: EVENT_TYPE_FROM_REQUEST_PAGE.READY;
  message: boolean;
};

export type RequestPageError = {
  type: EVENT_TYPE_FROM_REQUEST_PAGE.LOGIN_ERROR;
  message: string;
};

export type AccountNotFoundEvent = {
  type: EVENT_TYPE_FROM_REQUEST_PAGE.ACCOUNT_NOT_FOUND;
  message?: {
    email: string;
  };
};

export type AccountCreationRequestEvent = {
  type: EVENT_TYPE_FROM_REQUEST_PAGE.ACCOUNT_CREATION_REQUEST;
  message: { idToken: string } | { email: string; otpCode: string };
};

export type FromRequestPageData =
  | RequestPageReadyData
  | RequestPageError
  | AccountNotFoundEvent
  | AccountCreationRequestEvent;

export type EventFromRequestPage = Omit<MessageEvent, "data"> & {
  data: FromRequestPageData;
};
