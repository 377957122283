import { useCallback } from "react";

import { generateEmailConfirmationToken } from "../api/ncwallet";
import { PARENT_MESSAGE_TYPE, sendMessageToParent } from "../api/parentWindow";

type UseGoogleAccountCreationRequestProps = {
  onError: (msg: string) => void;
};

export const useGoogleAccountCreationRequest = ({
  onError,
}: UseGoogleAccountCreationRequestProps) => {
  const onAccountCreationRequest = useCallback(
    async ({ idToken }: { idToken: string }) => {
      try {
        const params = await generateEmailConfirmationToken({
          gmail: {
            token: idToken,
          },
        });
        sendMessageToParent({
          type: PARENT_MESSAGE_TYPE.ACCOUNT_CREATION_REQUEST,
          message: params,
        });
      } catch (err: any) {
        console.error(err?.message);
        onError("Account creation failed");
      }
    },
    []
  );

  return onAccountCreationRequest;
};
