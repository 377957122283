import {
  EVENT_TYPES_FROM_RESPONSE_PAGE,
  ResponsePageEvent,
} from "../types/responsePage";

export const isEventFromResponsePage = (
  event: MessageEvent
): event is ResponsePageEvent =>
  [window.location.origin].includes(event.origin) &&
  Object.keys(EVENT_TYPES_FROM_RESPONSE_PAGE).includes(event.data?.type);
