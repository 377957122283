import { FC } from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";

import { GoogleLoginBody } from "../../api/idp";
import { useGoogleAccountCreationRequest } from "../../hooks/useGoogleAccountCreationRequest";
import { useNewWindowLogin } from "../../hooks/useNewWindowLogin";
import commonStyles from "../../styles/common.module.scss";
import { EVENT_TYPE_TO_REQUEST_PAGE } from "../../types/eventsToRequestPage";
import Button from "../ui/Button/Button";

type NewWindowTriggerProps = {
  onBack: () => void;
  googleLoginParams: GoogleLoginBody;
  onError: (msg: string) => void;
  onAccountNotFound: () => void;
};

const NewWindowTrigger: FC<NewWindowTriggerProps> = ({
  onBack,
  onError,
  googleLoginParams,
  onAccountNotFound,
}) => {
  const onAccountCreationRequest = useGoogleAccountCreationRequest({ onError });

  const { openLoginWindow, isLoading } = useNewWindowLogin<GoogleLoginBody>({
    onError,
    sendParamsEventType: EVENT_TYPE_TO_REQUEST_PAGE.GOOGLE_LOGIN_BODY,
    onAccountCreationRequest,
    onAccountNotFound,
  });

  const onBtnClick = () => {
    openLoginWindow(googleLoginParams);
  };
  return (
    <div className={commonStyles.stack}>
      <div>
        <p className={commonStyles.h1}>
          <IoChevronBackCircleOutline
            onClick={onBack}
            className={commonStyles.inlineBackBtn}
          />
          Continue with Google
        </p>
      </div>

      <Button onClick={onBtnClick} disabled={isLoading}>
        Log in
      </Button>
    </div>
  );
};
export default NewWindowTrigger;
