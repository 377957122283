//TODO: create types for all messages-types
export enum PARENT_MESSAGE_TYPE {
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
  READY = "READY",
  SIZE_UPDATE = "SIZE_UPDATE",
  ACTIVE_SCREEN_UPDATE = "ACTIVE_SCREEN_UPDATE",
  BEFORE_SUCCESS_REDIRECT = "BEFORE_SUCCESS_REDIRECT",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_REJECTED = "LOGIN_REJECTED",
  ATTACH_WALLET_FAIL = "ATTACH_WALLET_FAIL",
  ACCOUNT_CREATION_REQUEST = "ACCOUNT_CREATION_REQUEST",
}

type AccountCreationRequestMessageBody = { email: string; token: string };

type AccountCreationRequestMessage = {
  type: PARENT_MESSAGE_TYPE.ACCOUNT_CREATION_REQUEST;
  message: AccountCreationRequestMessageBody;
};

type UnknownMessageType = Exclude<
  PARENT_MESSAGE_TYPE,
  PARENT_MESSAGE_TYPE.ACCOUNT_CREATION_REQUEST
>;

type UnknownMessage = {
  type: UnknownMessageType;
  message?: any;
};

type ParentMessage = AccountCreationRequestMessage | UnknownMessage;

export const sendMessageToParent = (msg: ParentMessage) => {
  window.parent.postMessage(msg, "*");
};
