import { ButtonHTMLAttributes, FC } from "react";

import cs from "classnames";

import { usePublicSettings } from "../../PublicSettingsProvider";
import styles from "./styles.module.scss";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<ButtonProps> = (props) => {
  const [settings] = usePublicSettings();
  return (
    <button
      {...props}
      className={cs(
        styles.button,
        settings?.isNbWallet ? styles["button--nbTheme"] : ""
      )}
    >
      {props.children}
    </button>
  );
};
export default Button;
