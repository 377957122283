import { useEffect, useRef } from "react";

export const useMounted = (cb: () => unknown) => {
  const isMountedRef = useRef(false);
  const cbRef = useRef(cb);
  cbRef.current = cb;
  useEffect(() => {
    if (isMountedRef.current) return;
    isMountedRef.current = true;
    cbRef.current();
  }, []);
};
