import { createContext, FC, ReactElement, useContext } from "react";

import { useSessionStorageState } from "ahooks";

import { PublicSettings } from "../types/publicSettings";

const SettingsContext = createContext<
  ReturnType<typeof useSessionStorageState<PublicSettings | null>>
>([null, () => {}]);

export type PublicSettingsProviderProps = {
  children: ReactElement;
};

const PublicSettingsProvider: FC<PublicSettingsProviderProps> = ({
  children,
}) => {
  //save settings in session to get it after redirect
  const settingsController = useSessionStorageState<PublicSettings | null>(
    "settings",
    { defaultValue: null }
  );
  return (
    <SettingsContext.Provider value={settingsController}>
      {children}
    </SettingsContext.Provider>
  );
};

export default PublicSettingsProvider;

export const usePublicSettings = () => {
  const settingsController = useContext(SettingsContext);

  return settingsController;
};
