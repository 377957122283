import {
  PUBLIC_INCOME_EVENT_TYPES,
  PublicIncomeEvent,
} from "../types/publicIncomeEvent";

export const isPublicIncomeEvent = (
  event: MessageEvent
): event is PublicIncomeEvent => {
  //TODO: check how to detect parent
  return (
    // event.source === window.parent &&
    Object.keys(PUBLIC_INCOME_EVENT_TYPES).includes(event.data?.type)
  );
};
