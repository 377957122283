export enum IDP_ERROR {
  "NotFound" = "NotFound",
}

export enum EVENT_TYPES_FROM_RESPONSE_PAGE {
  AUTH_RESPONSE = "AUTH_RESPONSE",
}

export type ResponsePageResponseData = {
  type: EVENT_TYPES_FROM_RESPONSE_PAGE.AUTH_RESPONSE;
  message: string;
};

export type ResponsePageEventData = ResponsePageResponseData;

export type ResponsePageEvent = Omit<MessageEvent, "data"> & {
  data: ResponsePageEventData;
};
