import { FC, InputHTMLAttributes, ReactElement } from "react";

import cs from "classnames";

import { usePublicSettings } from "../../PublicSettingsProvider";
import styles from "./styles.module.scss";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  icon?: ReactElement;
  onIconClick?: () => void;
};

const Input: FC<InputProps> = ({ error, icon, onIconClick, ...restProps }) => {
  const [settings] = usePublicSettings();

  return (
    <div className={styles.container}>
      <div className={styles.inputWrapper}>
        <input
          {...restProps}
          className={cs(
            styles.input,
            icon ? styles["input--icon"] : "",
            settings?.isNbWallet ? styles["input--withBg"] : ""
          )}
        />
        {icon && (
          <span className={styles.icon} onClick={onIconClick}>
            {icon}
          </span>
        )}
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
export default Input;
