import { FC, useEffect } from "react";
import { Portal } from "react-portal";

import { useExternal } from "ahooks";

import { GOOGLE_CLIENT_ID } from "../constants";
import { usePublicSettings } from "./PublicSettingsProvider";

const googleCbName = "googleSignInCb";

type GoogleSignInInitProps = {
  callback: (props: GoogleResponse) => void | Promise<void>;
};

export type GoogleResponse = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};

export const GoogleSignInInit: FC<GoogleSignInInitProps> = ({ callback }) => {
  const [publicSettings] = usePublicSettings();
  useExternal("https://accounts.google.com/gsi/client", {
    type: "js",
    js: {
      async: true,
    },
    // keepWhenUnused: true,
  });

  useEffect(() => {
    // @ts-expect-error unexpected variable in window
    window[googleCbName] = callback;
  }, [callback]);

  return (
    <>
      <Portal>
        <div
          id="g_id_onload"
          data-client_id={GOOGLE_CLIENT_ID}
          data-callback={googleCbName}
          data-auto_prompt="false"
        />
      </Portal>
      <div
        className="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme={publicSettings?.darkMode ? "filled_black" : "outline"}
        data-text="sign_in_with"
        data-shape="pill"
        data-logo_alignment="left"
        data-width="260"
      />
    </>
  );
};
