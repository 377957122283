import {
  EVENT_TYPE_FROM_REQUEST_PAGE,
  EventFromRequestPage,
} from "../types/eventsFromRequestPage";

export const isEventFromRequestPage = (
  event: MessageEvent
): event is EventFromRequestPage =>
  [window.location.origin].includes(event.origin) &&
  Object.keys(EVENT_TYPE_FROM_REQUEST_PAGE).includes(event.data?.type);
