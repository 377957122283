import { PublicSettings } from "./publicSettings";

export enum PUBLIC_INCOME_EVENT_TYPES {
  SET_LOGIN_SETTINGS = "SET_LOGIN_SETTINGS",
}

export type SetLoginSettingEventData = {
  type: PUBLIC_INCOME_EVENT_TYPES.SET_LOGIN_SETTINGS;
  message: PublicSettings;
};

export type PublicIframeIncomeEventData = SetLoginSettingEventData;

export type PublicIncomeEvent = Omit<MessageEvent, "data"> & {
  data: PublicIframeIncomeEventData;
};
