import { FC, FormEvent, useEffect, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";

import cs from "classnames";

import { isValidEmail } from "../helpers/validation";
import commonStyles from "../styles/common.module.scss";
import Input from "./ui/Input/Input";

type EmailFormProps = {
  onFinish: (email: string) => void;
};

export const EmailForm: FC<EmailFormProps> = ({ onFinish }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const validate = (emailValue: string) => {
    if (!isValidEmail(emailValue)) {
      return "Incorrect Email";
    }
  };

  useEffect(() => {
    setEmailError("");
  }, [email]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setDisabled(true);
    try {
      const error = validate(email);
      if (error) {
        throw new Error(error);
      }
      onFinish(email);
    } catch (err: any) {
      setEmailError(err.message || "Internal error");
      console.error(err);
    }
    setDisabled(false);
  };

  return (
    <div>
      <form onSubmit={onSubmit} className={commonStyles.oneLineForm}>
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Continue with Email"
          disabled={disabled}
          autoFocus
          icon={
            <button
              type="submit"
              className={cs(
                commonStyles.unstyledButton,
                commonStyles.iconButton
              )}
            >
              <AiOutlineSend style={{ marginTop: 2 }} />
            </button>
          }
        />
      </form>
      {emailError && <p className={commonStyles.textError}>{emailError}</p>}
    </div>
  );
};
